import PropTypes from "prop-types"
import React, { Component, useState } from 'react'

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FormData from "../../content/pages/formulaire.json"


export default class FormPage extends Component {
	  constructor(props) {
    super(props);

    this.layout = React.createRef();
  }

  componentDidMount() {
  	// console.log(FormData)
  }

  render() {
  	return (
  	  <Layout>
		    <SEO title="Formulaire de téléconsultation" description="Formulaire de téléconsultation à remplir pour prendre un rendez-vous de télémedcine avec le dermatologue Philippe Cadoret"/>
          <div className="container form">
            <h2>Formulaire de téléconsultation</h2>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdztxk1UK9HaWGcK3jt8YTvSaxLiEqFQoiMqL6AKMphRjb7dQ/viewform?embedded=true&hl=fr" width="100%" height="1400" frameBorder="0" marginHeight="0" marginWidth="0" onLoad={() => {window.parent.parent.scrollTo(0,0);}}>Loading…</iframe>
		      </div>
		  </Layout>
	  )
  }
}
